import React, { useEffect, useState } from "react";
import "./PreSales.css";
import { getImage } from "../../utils/getImage";

// getImage function ko use karke images ko dynamically get kar rahe hain
const image1 = getImage("sales-hub/engage-with-confidence.webp");
const image2 = getImage("sales-hub/Hassle-free-client-onboarding.webp");
const image3 = getImage("sales-hub/Add a subheading.webp");
const image4 = getImage("sales-hub/master-negotiations-with-ease.webp");
const image5 = getImage("sales-hub/confirmation-let's-make-it-official.webp");

const PreSales = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const totalHeight = document.body.scrollHeight - window.innerHeight;
      const currentScroll = window.scrollY;
      const newProgress = (currentScroll / totalHeight) * 100;
      setProgress(newProgress);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial call to set the progress on page load

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const sections = [
    {
      id: "section1",
      backgroundColor: "#fff",
      heading: "Engage with Confidence:",
      headingContent: "Engage",
      text: [
        "Discover effective email templates and calling scripts to kickstart conversations with potential clients. Make a strong first impression effortlessly. Start connecting today!",
      ],
      imageSrc: image1,
      link1: {
        url: require("../../assets/pdf/Proposal-Anantya.docx"),
        target: "_blank",  // This will open the link in a new tab
        text: "Mail Pitch",
        color: "#00bdff",
      },
      link2: {
        url: require("../../assets/pdf/Proposal-Anantya.docx"),
        target: "_blank",  // This will open the link in a new tab
        text: "Product Deck",
        color: "#00bdff",
      },

      pClassName: "scrollAnimationSection_1",
      h3ClassName: "section-heading_1",
      circleClassName: "circle-1",
    },
    {
      id: "section2",
      backgroundColor: "#fff",
      heading: "Experience the Power Anantya.ai: Watch Our Demo Video!",
      headingContent: "Demo Video",
      text: [
        "Discover the key features and benefits of our product firsthand through our demo video & how our solution can revolutionize any business with its user-friendly interface and robust capabilities.",
      ],
      imageSrc: image2,
      link2: {
        url: "https://drive.google.com/uc?export=download&id=1dIPBnjTRLBkfYIO6k3NoWN6jQokxKzON",
        target: "_blank",  // This will open the link in a new tab
        text: "Watch Live Demo",  // Text for single link
        color: "rgb(236, 175, 61)",
      },
      pClassName: "scrollAnimationSection_2",
      h3ClassName: "section-heading_2",
      circleClassName: "circle-2",
    },

    {
      id: "section3",
      backgroundColor: "#fff",
      heading: "Discover Use Cases",
      headingContent: "Use Cases",
      text: [
        "Welcome to our Use Case Discussion section. Here, explore how our product benefits different industries and departments.",
      ],
      imageSrc: image3,
      link1: {
        url: "https://drive.google.com/drive/folders/1kQJOKX0ykw7Prqxjnm42YiA_GqyurNcQ",
        target: "_blank", // This will open the link in a new tab
        text: "By Industry",
        color: "#f2ba95",
      },

      pClassName: "scrollAnimationSection_3",
      h3ClassName: "section-heading_3",
      circleClassName: "circle-3",
    },
    {
      id: "section4",
      backgroundColor: "#fff",
      heading: "Master Negotiations with Ease",
      headingContent: "Negotiations",
      text: [
        "Guidelines for negotiating deals, including obtaining approvals from Regional Head and Product Head. Negotiation primarily occurs via email.",
      ],
      imageSrc: image4,
      link1: {
        url: require("../../assets/pdf/Anantya_Updated_Conversation_prices.pdf"),
        text: "Global Pricing",
        color: "#54b9a6",
      },
      link2: {
        url: require("../../assets/pdf/Proposal-Anantya.docx"),
        text: "Proposal",
        color: "#54b9a6",
      },
      pClassName: "scrollAnimationSection_4",
      h3ClassName: "section-heading_4",
      circleClassName: "circle-4",
    },
    {
      id: "section5",
      backgroundColor: "#fff",
      heading: "Confirmation: Let's Make it Official",
      headingContent: "Confirmation",
      text: [
        "Congratulations on guiding your client through the initial stages of our product journey! Now, it's time to seal the deal and move forward towards success together. But before we proceed, let's ensure we're all on the same page; get confirmation by mail.",
      ],
      imageSrc: image5,
      pClassName: "scrollAnimationSection_5",
      h3ClassName: "section-heading_5",
      circleClassName: "circle-5",
    },
  ];

  return (
    <section>
      {sections.map((section, index) => (
        <div
          key={section.id}
          className="p-5 section"
          id={section.id}
          style={{
            backgroundColor: section.backgroundColor,
            "--offset": "20px",
            "--header-height": "90px",
            width: "100%",
            height: "calc(100vh - (var(--header-height) - var(--offset)))",
            position: "relative",
            paddingBottom: "var(--offset)",
            borderRadius: "var(--offset) var(--offset) 0 0",
          }}
        >
          <div className="row">
            {index % 2 === 0 ? (
              <>
                <div className="col-md-6 col-sm-12">
                  <img
                    className="solution__item__img"
                    src={section.imageSrc}
                    alt={section.heading}
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
                <div className="col-md-6 col-sm-12 my-auto text-lg-start text-center position-relative section-text">
                  <p className={section.pClassName}>{section.headingContent}</p>
                  <h3 className={section.h3ClassName}>{section.heading}</h3>
                  <p className="mt-30">{section.text[0]}</p>
                  {section.link1 && (
                    <a
                      href={section.link1.url}
                      target="_blank"  // This will open the link in a new tab
                      className="mt-30 internalpage_link"
                      style={{ color: section.link1.color }}
                      rel="noopener noreferrer"  // For security reasons
                    >
                      {section.link1.text}
                      <i className="fas fa-long-arrow-right ms-2 colorfa_1 my-auto"></i>
                    </a>
                  )}

                  {section.link2 && (
                    <a
                      href={section.link2.url}
                      target="_blank"  // This will open the link in a new tab
                      className="mt-3 internalpage_link"
                      style={{ color: section.link2.color }}
                      rel="noopener noreferrer"  // For security reasons
                    >
                      {section.link2.text}
                      <i className="fas fa-long-arrow-right ms-2 colorfa_1 my-auto"></i>
                    </a>
                  )}


                  <div className="additional-element">
                    <div className={`circle ${section.circleClassName}`}>
                      {("0" + (index + 1)).slice(-2)}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-6 col-sm-12 my-auto text-lg-start text-center position-relative section-text">
                  <p className={section.pClassName}>{section.headingContent}</p>
                  <h3 className={section.h3ClassName}>{section.heading}</h3>
                  <p className="mt-30">{section.text[0]}</p>
                  {section.link1 && (
                    <a
                      href={section.link1.url}
                      target="_blank"  // This will open the link in a new tab
                      className="mt-30 internalpage_link"
                      style={{ color: section.link1.color }}
                      rel="noopener noreferrer"  // For security reasons
                    >
                      {section.link1.text}
                      <i className="fas fa-long-arrow-right ms-2 colorfa_1 my-auto"></i>
                    </a>
                  )}

                  {section.link2 && (
                    <a
                      href={section.link2.url}
                      target="_blank"  // This will open the link in a new tab
                      className="mt-3 internalpage_link"
                      style={{ color: section.link2.color }}
                      rel="noopener noreferrer"  // For security reasons
                    >
                      {section.link2.text}
                      <i className="fas fa-long-arrow-right ms-2 colorfa_1 my-auto"></i>
                    </a>
                  )}

                  <div className="additional-element">
                    <div className={`circle ${section.circleClassName}`}>
                      {("0" + (index + 1)).slice(-2)}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <img
                    className="solution__item__img"
                    src={section.imageSrc}
                    alt={section.heading}
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ))}
      <div className="progress-container">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div>
    </section>
  );
};

export default PreSales;
